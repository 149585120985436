<template>
  <input type="file" accept="image/*" @change="onChange" />
  <div id="preview">
    <img v-if="item.imageUrl" :src="item.imageUrl" width="200" />
  </div>
</template>

<script>
  export default {
    name: 'imageUpload',
    data() {
      return {
        _imageFile: this.imageFile,
        item:{
            image : null,
            imageUrl: null
        }
      }
    },
    props: ['answer'],
    methods: {
      onChange(e) {
        const file = e.target.files[0]
        this.item.image = file
        this.item.imageUrl = URL.createObjectURL(file)
        this.answer[0].image = this.item.image
      }
    }
  } 
</script>