<template>

  <table class="col-12">
    <thead>
      <tr>
        <th class="width-25">Соответствие</th>
        <th class="width-25">Результат</th>
        <th class="width-25">Примечание</th>
        <th class="width-25">Фото</th>
      </tr>
    </thead>
    <tbody>
      <QuestionElement 
                v-for="question in questions" 
                :key="question.id"
                :id = "question.id"
                :question = "question.title"
                :answers = "answers"
            />
    </tbody>
  </table>
</template>

<script>
    import QuestionElement from "@/components/Reports/LaborProtection/Elements/protectionItemCreate/QuestionElement.vue";

    export default {
        components: { QuestionElement },
        props: ['questions', 'answers'],
        // computed: {
        //     protectionList () {
        //         return this.$store.state.questions
        //     }
        // }
    }
</script>