<template>
  <div class="row" v-if="postRequest">
    <div class="col-12 card">
      <strong class="error-feedback" >Идёт отправка данных. Пожалуйста, подождите.</strong>
    </div>
  </div>
  <div class="row" v-if="!postRequest">
    <div class="col-12 card">
      
      <div class="row justify-content-space-between top-24" >
        <h4 class="col-3 card-title">Добавить отчёт "{{ reportData.title }}"</h4>
        <div class="col-9 align-self-end right">
          <router-link :to="{path: '/reports/protection/' + reportData.id }"><button class="col-3 btn-secondary">Отменить</button></router-link>
          <button class="col-3 btn-primary" v-on:click="createChecklist"><span class="icon-Plus"></span>Сохранить</button>
          <button class="col-3 btn-primary" v-on:click="sendChecklist"><span class="icon-Check" ></span>Сдать</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 top-24">
          <div class="text-field">
            <label class="text-field__label" for="search">Организация</label>
            <div class="text-field__icon">
                <div class="select">
                  <select id="standard-select" v-model="this.selectedCompany" @change="findAutosList">
                    <option v-for="agent in agentsList" 
                      :value="{ id: agent.id }">{{ agent.name }}</option>
                  </select>

                </div>
            </div>
          </div>
        </div>
        <div class="col-12 top-24" v-if="reportData.car_necessary && selectedCompany != ''">
          <div class="text-field">
            <label class="text-field__label" for="search">Автомобиль</label>
            <div class="text-field__icon">
                <div class="select">
                  <div class="dropdown">
                    <input v-if="Object.keys(selectedCar).length === 0" v-model="this.inputValue" class="dropdown-input" type="text" placeholder="Регистрационный номер" />
                    <div v-else @click="resetSelection" class="dropdown-selected">
                      {{ selectedCar.number }}
                    </div>
                    <div v-show="inputValue" class="dropdown-list">
                      <div @click="selectItem(auto)" v-show="itemVisible(auto)" v-for="auto in autosList" :key="auto.id" class="dropdown-item">
                        {{ auto.number }}
                      </div>
                    </div>
                  </div>
                  

                  <!-- <select id="standard-select" v-model="this.selectedCar">
                    <option v-for="auto in autosList" 
                    :value="{ id: auto.id }">{{ auto.number }}</option>
                  </select> -->
                </div>
            </div>
          </div>
          
        </div>
        <div class="col-12 top-24" v-if="reportData.period_necessary && selectedCar != ''">
          <div class="text-field">
            <label class="text-field__label" for="search">Период отчёта</label>
            <div class="text-field__icon">
              <date-picker v-model:value="selectedPeriod" :value-type="'format'"></date-picker>
              <!-- <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите период" v-model="this.selectedPeriod"> -->
            </div>
          </div>
          <span class="error-feedback">{{ this.error }}</span>
        </div>
        <QuestionsTableView :questions="reportData.questions" :answers="this.answers"></QuestionsTableView>
        
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsTableView from "@/components/Reports/LaborProtection/Elements/protectionItemCreate/QuestionsTableView.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import axios from 'axios'

export default {
  components: { QuestionsTableView, DatePicker },
  data() {
    return {
      reportId: this.$route.params.id,
      selectedCompany: '',
      inputValue: '',
      selectedCar: '',
      selectedPeriod: '2019-11-05',
      autosList: [],
      error: '',
      postRequest: false,
      // answers: [{
      //     "id": 4,
      //     "question": "Журнал учета инструктажа водителей в наличии и ведется.",
      //     "comment": "",
      //     "answer": true,
      //     "image": null,
      //     "period_at": 2023,
      //     "created_at": "2023-09-01T15:55:49.563475Z"
      //   }],
      answers: []
    }
  },
  created() {
    this.reportId = this.$route.params.id
  },
  computed: {
    agentsList () {
      return this.$store.state.companies
    },
    reportData() {
      return this.$store.state.reports.find(report => report.id == this.reportId);
    }
  },
  mounted() {
    this.$store.dispatch("fetchCars");
    this.$store.dispatch("fetchCompanies");
  },
  methods: {

    resetSelection () {
      this.selectedCar = {}
      this.$nextTick( () => this.$refs.dropdowninput.focus() )
      this.$emit('on-item-reset')
    },
    selectItem (theItem) {
      this.selectedCar = theItem 
      this.inputValue = ''
      this.$emit('on-item-selected', theItem)
    },
    itemVisible (item) {
      let currentName = item.number.toLowerCase()
      let currentInput = this.inputValue.toLowerCase()
      return currentName.includes(currentInput)
    },
    findAutosList () {
      this.autosList = []
      let carList = []

      carList = this.$store.state.cars.filter((item) =>
          item.company == this.$store.state.companies.find((company) => company.id == this.selectedCompany.id).name
        )
      this.autosList = carList
    },
    createChecklist () {
      this.postRequest = true
      
      if ((this.reportData.car_necessary && this.selectedCar != '' && this.selectedCompany != '') | (!this.reportData.car_necessary && this.selectedCompany != '')) {
        var formData = new FormData();
        const today = new Date();

        axios.post(this.$store.state.domain+'/api/protection/'+this.reportId +'/', 
        {
          finish: false,
          company_title_id: this.selectedCompany.id,
          report_title_id: this.reportId,
          car_number_id: this.selectedCar.id,
          answers: this.answers,
          // period: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
          period: this.selectedPeriod,
        },
        {
          headers:{
            'Content-Type': 'multipart/form-data'
          }
        },
        )
        .then(() => {
          this.$router.push({ path: '/reports/'})
        })
      } else {
        this.error = 'Вы заполнили не все обязательные поля!'
      }
    },
    sendChecklist () {
      
      if (this.answers.map(a=>a.answer_result).includes('')) {
        this.error = 'Вы ответили не на все вопросы отчёта. Сдать отчёт можно только после заполнения всех полей.'
      } else {
        this.postRequest = true
        if ((this.reportData.car_necessary && this.selectedCar != '' && this.selectedCompany != '') | (!this.reportData.car_necessary && this.selectedCompany != '')) {
          var formData = new FormData();
          const today = new Date();

          axios.post(this.$store.state.domain+'/api/protection/'+this.reportId +'/', 
          {
            finish: true,
            company_title_id: this.selectedCompany.id,
            report_title_id: this.reportId,
            car_number_id: this.selectedCar.id,
            answers: this.answers,
            period: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
          },
          {
            headers:{
              'Content-Type': 'multipart/form-data'
            }
          },
          )
          .then(() => {
            this.$router.push({ path: '/reports/'})
          })
        } else {
          this.error = 'Вы заполнили не все обязательные поля!'
        }
      }
    },
  }

};
</script>
<style scoped>
.dropdown{
  position: relative;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}
.dropdown-input, .dropdown-selected{
  width: 90%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus, .dropdown-selected:hover{
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder{
  opacity: 0.7;
}
.dropdown-selected{
  font-weight: bold;
  cursor: pointer;
}
.dropdown-list{
  position: absolute;
  width: 50%;
  max-height: 500px;
  margin-top: 4px;
  /* overflow-y: auto; */
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 1;
}
.dropdown-item{
  display: flex;
  width: 90%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item:hover{
  background: #edf2f7;
}
.dropdown-item-flag{
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>