<template>
    <tr>
        <td>
            {{ question }}
        </td>
        <td>
            <div class="select">
                <select id="standard-select" @change="onChange($event)">
                    <option disabled value="">Выберите вариант</option>
                    <option value="" selected="selected"></option>
                    <option value="Да">Да</option>
                    <option value="Нет">Нет</option>
                    <option value="Не применимо">Не применимо</option>
                </select>
            </div>
        </td>
        <td>
            <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" placeholder="Введите комментарий" v-model="this.answer[0].comment">
            </div>
        </td>
        <td><ImageUploader :answer = "this.answer"/></td>
    </tr>
</template>

<script>
    import ImageUploader from "@/components/Shared/ImageUploader.vue";
    
    export default {
        components: { ImageUploader },
        data() {
            return {
                answer: [{
                    question: this.question,
                    comment: '',
                    answer_result: '',
                    image: ''
                }]
            }
        },
        props: ['id', 'question', 'answers'],
        methods: {
            onChange(event) {
                this.answer[0].answer_result = event.target.value
            }
        },
        mounted() {
            this.answers.push(this.answer[0])

        }
    }
</script>